import { useState } from "react";
import ViabilityService from "../services/ViabilityService";
import { ViabilityResponse } from "../types/ViabilityResponse";
import axios from "axios";

type ErrorState = {
  message: string;
  code?: number;
  data?: any;
} | null;

const useViability = () => {
  const [viabilityResponse, setViabilityResponse] = useState<ViabilityResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorState>(null);
  const [isHFC, setIsHFC] = useState<boolean | undefined>(undefined);

  /**
   * A function that checks the viability of an address given a zip code and street number.
   *
   * @param cep The zip code of the address to check.
   * @param numero The street number of the address to check.
   */
  const consultarViabilidade = async (cep: string, numero: string) => {
    setIsLoading(true);
    setError(null);

    try {
      var jwt = require('jsonwebtoken');
      const response = await axios.get(process.env.availableTechnologies, { headers: {'cep': cep, 'number': numero} })
      const responseDecoded = jwt.decode(response.data)
      ViabilityService.viabilityResponse = responseDecoded;
      setViabilityResponse(responseDecoded.data);
      const technologies = responseDecoded.data.technologies;
      const hasHFC = ViabilityService.resolveIsHFC(technologies);
      setIsHFC(hasHFC);
    } catch (error: any) {
      handleError(error.response)
      setError({
        message: error.message || 'Erro desconhecido',
        code: error.response?.status,
        data: error.response?.data,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (response: any) => {
    if (response.data.errors.code === '500-001') {
      ViabilityService.viabilityResponse = response;
      ViabilityService.viabilityResponse.data.technologies = [
        {
          "gpon": false,
          "name": "Satellite",
          "tv": true,
          "phone": true,
          "internet": true
        }
      ]
    }
  }

  return {
    viabilityResponse,
    isLoading,
    error,
    isHFC,
    consultarViabilidade,
  };
};

export default useViability;
