import React, { FormEvent, SyntheticEvent } from 'react';
import { Button, Subtitle, Text } from 'mondrian-react';
import ReactLoading from 'react-loading';

import ModalService from '../../../../../services/ModalService';
import ResidentialInfoService from '../../../../../services/ResidentialInfoService';
import CroService from '../../../../../services/CroService';

import Form from './form';
import FormContext from './form/context/FormContext';
import FormReducer from './form/reducer/FormReducer';
import CpfControl from './form/controls/CpfControl';
import CelularControl from './form/controls/CelularControl';
import CepControl from './form/controls/CepControl';
import NumeroControl from './form/controls/NumeroControl';
import CheckboxControl from './form/controls/CheckboxControl';
import { FormActionTypes } from './form/types/FormReducer';

import useMobileInfo from '../../../../../hooks/useMobileInfo';
import { SessionStorageKeys } from '../../../../../types/SessionStorageKeys';
import JaSouClienteContext from '../../../../../contexts/jornada-ja-sou-cliente';

import styles from './SendCodeComponent.module.scss';
import useViability from '../../../../../hooks/useViability';
import { useRouter } from 'next/router';

let keyToGetInStorage = [
  'celularId',
  'internetId',
  'foneId',
  'tvId',
  'additionalIds',
  'subprodutosIds'
];

const MINHA_CLARO_RESIDENCIAL_URL = 'https://minhaclaroresidencial.claro.com.br/mude-seu-plano/autenticacao';

type DisplayOptions = 'form' | 'mensagemResidencial';

const SendCodeComponent: React.FC = () => {
  const [formState, dispatchFormState] = React.useReducer(FormReducer, {
    controls: {
      cpf: {
        value: '',
        error: undefined,
        touched: false,
        valid: undefined,
        validating: false,
        disabled: false,
        required: true,
        focused: false,
        loading: false
      },
      celular: {
        value: '',
        error: undefined,
        touched: false,
        valid: undefined,
        validating: false,
        disabled: true,
        required: true,
        focused: false,
        loading: false
      },
      cep: {
        value: '',
        error: undefined,
        touched: false,
        valid: undefined,
        validating: false,
        disabled: true,
        required: true,
        focused: false,
        loading: false
      },
      numero: {
        value: '',
        error: undefined,
        touched: false,
        valid: undefined,
        validating: false,
        disabled: true,
        required: true,
        focused: false,
        loading: false
      },
      checkbox: {
        checked: false,
        touched: false,
        valid: true,
        disabled: true,
        required: false,
        focused: false,
      }
    },
    valid: false,
  });

  const context = React.useContext(JaSouClienteContext);
  const { token, error, sendSMS, isLoading: sendSmsLoading } = useMobileInfo();
  const router = useRouter();
  const { isLoading, error: viabilityError, isHFC, consultarViabilidade } = useViability();
  const [exibir, setExibir] = React.useState<DisplayOptions>('form');

  const fecharModal = (): void => {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'fechar'
    });
    sessionStorage.removeItem(SessionStorageKeys.OUTRO_ENDERECO_JA_SOU_CLIENTE);
    ModalService.close();
  }

  const handleVoltar = () => {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'voltar'
    });
    sessionStorage.removeItem(SessionStorageKeys.OUTRO_ENDERECO_JA_SOU_CLIENTE);
    ModalService.close();
  }

  const handleNovoEndereco = (e?: SyntheticEvent<Element, Event>): void => {
    e.preventDefault();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'novo-endereco'
    });
    context.setOutroEndereco(true);
    sessionStorage.setItem(SessionStorageKeys.OUTRO_ENDERECO_JA_SOU_CLIENTE, 'true');
    setExibir('form');
  }

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'avancar'
    });
    consultarViabilidade(formState.controls.cep.value, formState.controls.numero.value);
  }

  const createBaseURL = () => {
    keyToGetInStorage.map((key, index) => {
      if (sessionStorage.getItem(key) === null) {
        delete keyToGetInStorage[index]
      } else {
        keyToGetInStorage[index] = `${key}=${sessionStorage.getItem(key)}`
      }
    });
    keyToGetInStorage = keyToGetInStorage.filter(el => el !== undefined)
    const affiliateId = sessionStorage.getItem('affiliateId') ?? 'rywhqLduv';
    keyToGetInStorage.push('affiliateId=' + affiliateId);
    keyToGetInStorage.push('origin=planos');
    keyToGetInStorage.push('CA=1');
    return keyToGetInStorage.join('&')
  }

  const handleEnderecoAtual = (e?: SyntheticEvent<Element, Event>): void => {
    e.preventDefault();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'utilizar-endereco-atual'
    });
    window.location.replace(MINHA_CLARO_RESIDENCIAL_URL + createBaseURL());
  }

  const showError500 = React.useCallback((): void => {
    ModalService.close();
    router.push('/error/500');
  }, [router])

  React.useEffect(() => {
    const triggerSMS = async () => {
      if (isHFC !== undefined) {
        await sendSMS(formState.controls.celular.value);
      }
    };

    triggerSMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHFC]);

  React.useEffect(() => {
    const handleViabilityResponse = async () => {
      const viabilityResponse = viabilityError?.data;

      if (viabilityResponse && viabilityResponse.errors.code === '500-001') {
        try {
          await sendSMS(formState.controls.celular.value);
        } catch (error) {
          console.error('Erro ao enviar SMS', error)
        }
      } else if (viabilityError && viabilityResponse.errors.code !== '500-001') {
        ModalService.close();
        showError500();
      }
    }

    handleViabilityResponse();
  }, [showError500, viabilityError, formState]);

  React.useEffect(() => {
    // Executa quando o token for atualizado
    if (token) {
      // Armazena o token no sessionStorage
      sessionStorage.setItem(SessionStorageKeys.SMS_ECARE_TOKEN_JA_SOU_CLIENTE, token);
      // Navega para a etapa de validação do cliente móvel
      context.navigateTo('mobileClientValidation');
    }
  }, [context, token]);

  React.useEffect(() => {
    // Executa quando ocorrer um erro
    // Navega para a etapa de apiError quando o envio do SMS falhar
    if (error) {
      context.navigateTo('apiError');
    }
  }, [context, error]);

  React.useEffect(() => {
    // Verifica se o CPF é válido e não está selecionada a opção de outro endereço
    if (formState.controls.cpf.valid && !context.outroEndereco) {
      // Executa a validação do CPF na base residencial
      validarCpfNaBaseResidencial();
    } else {
      // Caso contrário, exibe o formulário
      setExibir('form');
    }

    // Função assíncrona para validar o CPF na base residencial
    async function validarCpfNaBaseResidencial(): Promise<void> {
      // Obtém o valor do CPF do estado do formulário
      const cpf = formState.controls.cpf.value;

      try {
        // Define o estado do campo CPF como carregando e desabilitado
        dispatchFormState({
          type: FormActionTypes.UPDATE_CONTROL,
          payload: {
            control: {
              name: 'cpf',
              state: {
                loading: true,
                disabled: true,
              },
            },
          },
        });

        // Consulta o CPF na API da base residencial
        const response = await ResidentialInfoService.consultarCpf(cpf);

        // Verifica se o cliente é encontrado na base residencial
        const isCustomer = response.data.status === 'CONECTADO';

        // Define o estado de cliente residencial no contexto e armazena no sessionStorage
        context.setResidentialCustomer(isCustomer);
        sessionStorage.setItem(SessionStorageKeys.RESIDENTIAL_CUSTOMER_JA_SOU_CLIENTE, 'true');

        if (isCustomer) {
          // Se o cliente for encontrado, exibe a mensagem de cliente residencial
          setExibir('mensagemResidencial');
        } else {
          // Se o cliente não for encontrado, exibe o formulário e habilita os campos desabilitados
          setExibir('form');
          dispatchFormState({ type: FormActionTypes.ENABLE_DISABLED_FIELDS });
        }
      } catch (error) {
        // Em caso de erro na API, redireciona para a página de erro
        context.navigateTo('apiError');
      } finally {
        // Restaura o estado do campo CPF para não carregando e habilitado
        dispatchFormState({
          type: FormActionTypes.UPDATE_CONTROL,
          payload: {
            control: {
              name: 'cpf',
              state: {
                loading: false,
                disabled: false,
              },
            },
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.controls.cpf.valid]);

  return (
    <div id="send-code-container" className={styles.container}>
      <div id="banner" className={styles.banner}></div>
      <div id="content" className={styles.content}>
        <button id="button-fechar-modal" className={styles.buttonFecharModal}
          onClick={fecharModal}>
          <span className="mdn-Icon-circulo-fechar mdn-Icon--md"></span>
        </button>

        <Subtitle xxs className={styles.mdnSubtitle}>
          Informe o número de celular e CPF do titular da sua linha móvel para acessar descontos exclusivos em planos de internet ou TV:
        </Subtitle>

        <FormContext.Provider value={{ formState, dispatchFormState }}>
          <Form onSubmit={handleOnSubmit}>
            <CpfControl />
            {exibir === 'form' && (
              <>
                <CelularControl />
                <CepControl />
                <NumeroControl />
                <CheckboxControl />

                <div id="buttons-container" className={`mdn-Col-xs-12 ${styles.btnsContainer}`}>
                  <Button type='button'
                    secondary
                    onClick={handleVoltar}>
                    Voltar
                  </Button>
                  <Button type='submit' primary
                    disabled={!formState.valid || formState.controls.cpf.loading || isLoading || sendSmsLoading}>
                    {isLoading || sendSmsLoading
                      ? <ReactLoading
                        type="spin"
                        color="#FFF"
                        height={20}
                        width={20}
                      /> : 'Avançar'
                    }
                  </Button>
                </div>
              </>
            )}
          </Form>
        </FormContext.Provider>

        {exibir === 'mensagemResidencial' && (
          <>
            <Text id="error-message" body className={styles.errorMessage}>
              Notamos que você já é cliente Claro!
            </Text>
            <Text body>
              Assim, você pode contratar planos para o endereço atual ou
              para um novo endereço, basta selecionar abaixo a opção desejada:
            </Text>
            <Button md secondary
              onClick={handleEnderecoAtual}>
              Utilizar endereço atual
            </Button>
            <Button md primary onClick={handleNovoEndereco}>
              Novo endereço
            </Button>
          </>
        )}

        {exibir === 'form' && (
          <div id="warning-message" className={styles.warningMessage}>
            <span className="mdn-Icon-circulo-exclamacao mdn-Icon--md"></span>
            <Text caption>
              É importante preencher todos os seus dados para que possamos
              localizar as melhores ofertas disponíveis para seu(s)
              endereço(s).
            </Text>
          </div>
        )}

      </div>
    </div >
  );
}

export default SendCodeComponent;
